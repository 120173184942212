import React from "react";
import "react-multi-carousel/lib/styles.css";
import "./slider.scss";
import Carousel from "react-multi-carousel";
import { IKImage } from 'imagekitio-react';

const Slider = ({ data, setImage }) => {
  const baseURL = "https://storage.googleapis.com/motorpedia/prod/car-images/";
  const imageMock = "default_small_car.png?updatedAt=1721644530070"; 
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 3,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  return (
    <>
      {data && (
        <Carousel responsive={responsive}>
          {data.map((ele, i) => (

            <IKImage
              key={i}
              urlEndpoint="https://ik.imagekit.io/dmzu7r6rr"
              onClick={() => setImage(i)}
              path={`${ele?.img_url.split(" ").join("%20")}`.replace(baseURL, "")|| imageMock} 
              transformation={[
                {width:200},
                {quality:80},
                { format: "webp" }
              ]}
              loading="lazy"
              alt={`Image ${i}`}
              className="carousel-item"
            ></IKImage>

          ))}
        </Carousel>
      )}
    </>
  );
};

export default Slider;
